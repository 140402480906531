// import React, { useState } from 'react'
import Table from 'react-bootstrap/Table';
// import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import axios from 'axios'


const BlogList = () => {

    const [data,SetData]=useState([])

    const fetchGalleryData = async () => {

        
        try {
            axios.get('http://localhost:5000/api//blog/find-blog')
                .then((response) => {
                    // console.log('response',response);
                    SetData(response.data.data)
                })
                .catch((error) => {
                    console.log('error', error);

                })
        } catch (error) {
            console.log('error'.error.message);
        }

    }
    useEffect(() => {
        fetchGalleryData();

    }, [])
  return (
    <div className='container'>
    <div className='container'>
        <h1>Blog List</h1>
    </div>
    <Table>
        <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>URL</th>
                <th> Id</th>

                {/* <th>edit</th> */}
                {/* <th>Status</th> */}
                {/* <th>feature</th> */}

            </tr>
        </thead>
        <tbody>

            {
                data && data.map((element, index) => (
                    <tr>
                        <td>{index + 1}</td>
                        <td>{element.blogName}</td>
                        <td>{element.blogUrl}</td>
                        <td>{element._id}</td>
                        

                        {/* <td>

                            {element.status}
                        </td> */}

                       
                        {/* <td>
                        {element.isFeature && element.isFeature ==="Y" ?(<FaEye onClick={()=>UpdateIsFeatured(element._id)} />) :(<FaEyeLowVision onClick={()=>UpdateIsFeatured(element._id)}/>)}
                            </td> */}


                    </tr>

                ))
            }

        </tbody>
    </Table>
</div>
  )
}

export default BlogList
