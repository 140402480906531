// import React, { useState } from 'react'
import Table from 'react-bootstrap/Table';
// import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { Bounce, toast } from 'react-toastify';
import { FaToggleOn,FaToggleOff } from "react-icons/fa";
import { FaTrash,  } from "react-icons/fa";

import axios from 'axios'


const GalleryList = () => {

    const [data,SetData]=useState([])

    const fetchGalleryData = async () => {

        
        try {
            axios.get('http://localhost:5000/api/gallery/find-gallery')
                .then((response) => {
                    // console.log('response',response);
                    SetData(response.data.data)
                })
                .catch((error) => {
                    console.log('error', error);

                })
        } catch (error) {
            console.log('error'.error.message);
        }

    }
    useEffect(() => {
        fetchGalleryData();

    }, [])

    const changeStatus=async(id)=>{
        axios.get(`http://localhost:5000/api/gallery/update-status?_id=${id}`).then(async(response)=>{
            // console.log(response);
            const confirm=window.confirm('Do you really want to change gallery status ?')
            if(confirm){
            toast.success(response.data.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              })
             await fetchGalleryData();
            }
        }).catch((error)=>{
            console.log(error);
            
        })
    }

    const useToken=localStorage.getItem('token') 

    const deleteGallery=async(id)=>{
        const formData=new FormData()
        formData.append("_id",id)

        axios.post('http://localhost:5000/api/gallery/delete-gallery',formData,{
            headers:{
                "authorization":useToken
            }
        }).then( async(response)=>{
        //   console.log(response);
        //   alert(' confirm delete gallery')
        const confirm=window.confirm('Do you really want to delete gallery?')
        if(confirm){
          toast.success(response.data.message,{
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          })
         await fetchGalleryData();
        }
          
        }).catch((error)=>{
            console.log(error);
            toast.error(error.message,{
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              })
        })
    }
  return (
    <div className='container'>
    <div className='container'>
        <h1>Gallery List</h1>
    </div>
    <Table>
        <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Image URL</th>
                <th> Id</th>

                {/* <th>edit</th> */}
                <th>Status</th>
                <th>delete</th>

            </tr>
        </thead>
        <tbody>

            {
                data && data.map((element, index) => (
                    <tr>
                        <td>{index + 1}</td>
                        <td>{element.galleryName}</td>
                        <td className='categoryImage'><img src={element.galleryImage} alt={element.galleryImage} /></td>
                        <td>{element._id}</td>
                         <td>
                            {element.status&&element.status==='Y'?(<FaToggleOn title='status on' onClick={()=>changeStatus(element._id)}/>):(<FaToggleOff title='status off' onClick={()=>changeStatus(element._id)}/>)}
                         </td>

                                
                         <td><FaTrash title='Delete gallery' onClick={()=>deleteGallery(element._id)} /></td>
                    </tr>

                ))
            }

        </tbody>
    </Table>
</div>
  )
}

export default GalleryList
