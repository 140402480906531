import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import { Bounce, toast } from 'react-toastify';
import { FaToggleOn,FaToggleOff } from "react-icons/fa";


const SubCategoryList = () => {
    const [data,SetData]=useState('')

    const FetchSubCatList=()=>{
        axios.get(`https://tanish.xpertart.com/api/sub-category/find-sub-category`).then((response)=>{
            // console.log(response.data.data);
            SetData(response.data.data);

            toast.success(response.data.data.message,{
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });


        }).catch((error)=>{
        console.log(error);

        toast.error(error.data.data.message,{
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });

        
        })


    }
    useEffect(()=>{

        FetchSubCatList()
    },[])

    const ChangeStatus=async(id)=>{
      axios.get(`https://tanish.xpertart.com/api/sub-category/update-status?_id=${id}`).then(async(response)=>{
        // console.log(response);

        const confirm=window.confirm('Do you really want to change subCategory status ?')
        if(confirm){

        
        toast.success(response.data.message,{
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        })
       await FetchSubCatList()
      }
      }).catch((error)=>{
        console.log(error);
        
      })
    }

  return (
    <div>
        <Table striped>
      <thead>
        <tr>
          <th>#</th>
          <th>Sub-Category-Name</th>
          <th>Image</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {data&&data.map((element,index)=>(

       
        <tr>
          <td>{index+1}</td>
          <td>{element.name}</td>
          <td><img src={element.image} alt={element.image} style={{width:"40px"}}/></td>
          <td>{element.status&&element.status==="Y"?(<FaToggleOn title='status on' onClick={()=>ChangeStatus(element._id)}/>):(<FaToggleOff title='status Off' onClick={()=>ChangeStatus(element._id)}/>)}</td>
        </tr>
        ))}
      </tbody>
    </Table>
    </div>
  )
}

export default SubCategoryList
