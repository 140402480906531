import React, { useState } from 'react';
import "./Header.css";
import { Link, useNavigate } from 'react-router-dom';
import { IoCartOutline } from "react-icons/io5";
import { useContext } from 'react';
import { AuthContext } from '../Store/Auth';
import { Badge, Button } from 'react-bootstrap';
import { Bounce, toast } from 'react-toastify';
const Header = (props) => {
    const { isLoggedIn, logout, hasRole, authData, cartItemCounts } = useContext(AuthContext);

    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const userToken = localStorage.getItem('token');
    const userName = localStorage.getItem('name');

    const handleLogOut = async () => {
        let confirm = window.confirm("Do you really want to logout ?");
        if (confirm) {
            await logout();
            toast.success("logged out successfully.", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            navigate('/sign-in')
        } else {
            toast.success("You cancelled logout 🤗.", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    }

    return (
        <header>
            <div className="container">
                <div id="head">
                    <div className="logo">
                        <Link to="/"><img src="./images/bird-colorful-logo-gradient-vector_343694-1365.avif" alt="logo_image" /></Link>
                        <div className='logo-text'>
                            <Link to="/"><h2>iMona's</h2></Link>
                        </div>
                    </div>
                    <div className="search">
                        <input type="text" placeholder="Search" name="search" onChange={(e) => setSearch(e.target.value)} value={search} />
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <div className="pages">
                        <ul>
                            <li><Link to='/'>Home</Link></li>
                            <li><Link to='/about'>About</Link></li>
                            <li><Link to='/products'>Products</Link></li>
                            <li><Link to='/contact-us'>Contact Us</Link></li>
                            <li><Link to='/support'>Support</Link></li>
                            <li><Button className='qwertyuiop' onClick={() => navigate('/add-cart')}>
                                <IoCartOutline style={{fontSize:"1.6rem"}} /><Badge className='poiuytrewq' style={{backgroundColor:"red"}}>{cartItemCounts}</Badge> Cart
                            </Button></li>
                            {
                                (!userToken) ? (
                                    <li className="signIn"><Link to='/sign-in'>Sign in</Link></li>
                                ) : (


                                    <li className="dropdown">
                                        <Link to="#" onClick={toggleDropdown}>{userName}</Link>
                                        {dropdownOpen && (
                                            <div className="dropdown-menu" content='center'>
                                                <button style={{ backgroundColor: "Blue", color: "White" }} onClick={handleLogOut}>Logout</button>

                                                <li className="category-form"><Link to='/category-form'>Add-Category</Link></li>

                                                <li className='category-list'><Link to='/category-list'>Category-list</Link> </li>
                                                {/* <Link to='/new-contact-list'>contactList</Link> */}
                                                <li className="new-contact-list"><Link to='/new-contact-list'>Contact-list</Link></li>
                                                <li className="Product-form"><Link to='/product-form'>Product-form</Link></li>

                                                <li className='product-list'><Link to='/product-list'>Product-list</Link></li>
                                                <li> <Link to='/add-slider'>Add-Slider</Link></li>
                                                <li><Link to='/slider-list'>Slider-list</Link> </li>
                                                <li><Link to='/subcategory-form'>SubCat-Form</Link></li>
                                                <li><Link to='/sub-cat-list'>SubCat-List</Link></li>

                                                <li><Link to='/gallery-form'>Add-Gallery</Link></li>
                                                <li><Link to='/gallery-list'>Gallery-list</Link></li>
                                                <li><Link to='/add-blog'>Add-Blog</Link></li>
                                                <li><Link to='/blog-list'>Blog-list</Link></li>
                                                <li><Link to='/my-products'>Add-My-products</Link></li>
                                                <li><Link to='/my-products-list'>My-products-list</Link></li>
                                                {/* <Link to='/product-form'>Product-form</Link> */}
                                                <button onClick={() => setDropdownOpen(false)}>Cancel</button>
                                            </div>
                                        )}
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
