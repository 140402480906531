import React, { useEffect } from 'react'
import "./Feature.css"
import { useQuery } from 'react-query'
import { TailSpin } from 'react-loader-spinner'
import Slider from '../Sliders/Slider'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import TreandingProduct from './TreandingProduct';

console.log('>>>>>>>>>>>>>>>>>',process.env.apiUrl);



const fetchCategory = async () => {
    const response = await fetch('https://tanish.xpertart.com/api/category/find-category?status=Y&isFeature=Y');

    if (!response.ok) {
        throw new Error("Network response was not ok !");
    } else {
        return response.json();
    }
}

const Feature = (props) => {


//     const {catpId}=useParams();


//     const categoryByProduct=async(id)=>{
// axios.get(`http://localhost:5000/api/product-list-by-categoryId?_id=${id}`).then((response)=>{
//     console.log('response>>>>>>',response);
    
// }).catch((error)=>{
// console.log(error);

// })

//     }
//     useEffect(()=>{
//         categoryByProduct(catpId)
//     },[catpId])

    const { isLoading, error, data } = useQuery(['getCategory'], fetchCategory)


    if (isLoading) return <TailSpin
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
    />;

    if (error) {
        return (
            <div>
                Error: {error.message}
            </div>
        )
    }
    const filteredData = data && data.data.filter(element=>element.status === "Y" )

    return (
        <section>
            <div className="container">
                <div className="sell_products">
                    {
                       filteredData && filteredData.map((element, index) => (
                
                            <div className="feature-product" key={index}>
                                <div className="pro_image">
                                <Link to={`/category-product/${element._id}`}>
                                    <img src={element.image} alt="" />
                                    </Link>
                                </div>
                                <div className="pro_name">
                                    <h4>{element.category}</h4>
                                </div>
                            </div>
                           
                        ))}
                </div>
            </div>
<Slider/>

<div>
    <TreandingProduct/>
</div>
          
        </section>
    )
}

export default Feature
