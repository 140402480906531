import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
const Slider = () => {
const [data,SetData]=useState('')
const viewSlider=()=>{
    axios.get(`https://tanish.xpertart.com/api/slider/find-slider`).then((response)=>{
        // console.log(response.data.data);
        SetData(response.data.data)

        // const { isLoading, error, data } = useQuery(['getSlider'], viewSlider)


        // if (isLoading) return <TailSpin
        //     height="80"
        //     width="80"
        //     color="#4fa94d"
        //     ariaLabel="tail-spin-loading"
        //     radius="1"
        //     wrapperStyle={{}}
        //     wrapperClass=""
        //     visible={true}
        // />;
        
    }).catch((error)=>{
        console.log(error);
        
    })
}
useEffect(()=>{
    viewSlider();
},[])
// viewSlider()


    const FilterData= data&&data.filter(element=>element.status==='Y')
  return (
    <div>
        <div className="containerFull" style={{width:"1234px",margin:"auto", }}>
            <Carousel style={{marginBottom:"1rem", marginTop:'2rem',}}>

                {FilterData&&FilterData.map((element,index)=>(

               
                <Carousel.Item key={index}  >
                    <img style={{borderRadius:'40px'}}
                        className="d-block w-100"
                        src={element.image}
                        alt={element.image}
                    />
                    
                </Carousel.Item>
                 ))}
                {/* <Carousel.Item>
                    <img
                        className="d-block w-100"
                       src="../images/s2.webp"
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="../images/s3.webp"
                        alt="First slide"
                    />
                    
                </Carousel.Item>

                <Carousel.Item>
                    <img
                        className="d-block w-100"
                       src="../images/s4.webp"
                        alt="Fourth slide"
                    />
                </Carousel.Item>


                <Carousel.Item>
                    <img
                        className="d-block w-100"
                       src="../images/s5.webp"
                        alt="fifth slide"
                    />
                </Carousel.Item> */}

            </Carousel>
            </div>
    </div>
  )
}

export default Slider
