import React,{useEffect,useState} from 'react'
import "./ProductDetail.css";
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../Store/Auth';
import { Bounce, toast } from 'react-toastify';

const ProductDetails = () => {

    
    const { addToCart } = useContext(AuthContext);


    const handleAddToCart = async (id) => {
        const payload = {
            productId: id,
            symbol: "+",
        }
        const response = await addToCart(payload);
        if (response.data) {

            toast.success(`🦄 ${response.data.message}`, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
            navigate(`/add-cart`)
            return;
        }else {
            console.log("response.false >>> ", response.data);
            return
        }
    }





    const { id } = useParams();
    const [productDetails, setproductDetails] = useState('')

  const navigate=useNavigate()
    const fetchProductById = async (id) => {
        axios.get(`https://tanish.xpertart.com/api/products/find-Products?_id=${id}`)
            .then((response) => {
console.log('response',response);

                if(response.data.success){
                    setproductDetails(response.data.product);
                }
                console.log('response.data.product=>', response.data.success);

            })
            .catch((error) => {
                console.log('Error=>', error.message);
            })
    }

    useEffect(() => {
        fetchProductById(id);
    }, [id])
    

    // const { isLoading, error } = useQuery(['getCategory'], ()=>fetchCategory(id))

    //     if (isLoading) return <TailSpin
    //     height="80"
    //     width="80"
    //     color="#4fa94d"
    //     ariaLabel="tail-spin-loading"
    //     radius="1"
    //     wrapperStyle={{}}
    //     wrapperclassName=""
    //     visible={true}
    // />;

    //     if (error) {
    //         return (
    //             <div>
    //                 Error: {error.message}
    //             </div>
    //         )
    //     }


    return (
        <>
            <div className="card-wrapper">
                <div className="card">
                    <div className="product-imgs">
                        <div className="img-display">
                            <div className="img-showcase">
                                <img src={productDetails && productDetails.image} alt="Product pic" />
                               
                            </div>
                        </div>
                        <div className="img-select">
                            <div className="img-item">
                                <Link to="#" data-id="1">
                                    <img src={productDetails && productDetails.image} alt="Product pic" />
                                </Link>
                            </div>
                            <div className="img-item">
                                <Link to="#" data-id="2">
                                    <img src={productDetails && productDetails.image} alt="Product pic" />
                                </Link>
                            </div>
                            <div className="img-item">
                                <Link to="#" data-id="3">
                                    <img src={productDetails && productDetails.image} alt="Product pic" />
                                </Link>
                            </div>
                            <div className="img-item">
                                <Link to="#" data-id="4">
                                    <img src={productDetails && productDetails.image} alt="Product pic" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="product-content">
                        <h2 className="product-title">{productDetails && productDetails.product_name}</h2>
                        <Link to="#" className="product-link">visit nike store</Link>
                        <div className="product-rating">
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star-half-alt"></i>
                            <span>{productDetails&&productDetails.rating}</span>
                        </div>

                        <div className="product-price">
                            <p className="last-price">Old Price: <span>${productDetails && productDetails.price}</span></p>
                            <p className="new-price">New Price: <span>${productDetails && productDetails.price} (5%)</span></p>
                        </div>

                        <div className="product-detail">
                            <h2>about this item: </h2>
                            
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo eveniet veniam tempora fuga tenetur placeat sapiente architecto illum soluta consequuntur, aspernatur quidem at sequi ipsa!</p> */}
                            <p>{productDetails&&productDetails.details}</p>
                            <ul>
                                <li>Color: <span>Black</span></li>
                                <li>Available: <span>in stock</span></li>
                                <li>Category: <span>{productDetails&&productDetails.categoryId.category}</span></li>
                                <li>Shipping Area: <span>All over the world</span></li>
                                <li>Shipping Fee: <span>Free</span></li>
                            </ul>
                        </div>

                        <div className="purchase-info">
                            <input type="number" min="0" value="1" />
                            <Link to='' onClick={()=>handleAddToCart(productDetails._id)} >
                                <button  type="button" className="btn"  >
                                    Add to Cart <i className="fas fa-shopping-cart"></i>
                                </button>
                            </Link>
                            <button type="button" className="btn">Compare</button>
                        </div>

                        <div className="social-links">
                            <p>Share At: </p>
                            <Link to="#">
                                <i className="fab fa-facebook-f"></i>
                            </Link>
                            <Link to="#">
                                <i className="fab fa-twitter"></i>
                            </Link>
                            <Link to="#">
                                <i className="fab fa-instagram"></i>
                            </Link>
                            <Link to="#">
                                <i className="fab fa-whatsapp"></i>
                            </Link>
                            <Link to="#">
                                <i className="fab fa-pinterest"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductDetails