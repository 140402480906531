import React from 'react'
import "./Support.css"
import { Link } from 'react-router-dom'

const Support = () => {
    return (
        <div id="support">
            <div className="container">
            <hr />
            <div className="form_box-support">
                <div className="support-form">
                    <form>
                        <div className="support-Form_container">

                            <h3>E-mail: <Link to="mailto:imonas@gmail.com">imonas@gmail.com</Link> <Link to="mailto:imonas@gmail.com">Email Now</Link></h3>
                            <h3>Telephone : <Link to="tel:0141-224353">+0141-224353</Link> <Link to="tel:0141-224353">TelePhone Now</Link></h3>
                            <h3>Facebook : <Link to="/facebook.com">imonas</Link></h3>

                            <br />
                            <p>
                                <h3 className='Address'>Address:</h3>
                                123 Fake Street, Faketown, FK1 2AB
                            </p>

                        </div>
                    </form>
                </div>
            </div>
        </div>
        </div>    
    )
}

export default Support
