import axios from 'axios'
import React, { useState,useEffect } from 'react'
import Table from 'react-bootstrap/Table';


const NewContactList = () => {

const [data,setData]=useState([])


    const fetchContactData=async()=>{

        try {
            axios.get('https://tanish.xpertart.com/api/contact/view-all-contact')
            .then((response)=>{
                // console.log('response',response.data.data);
                setData(response.data.data)
            })
            .catch((error)=>{
           console.log('error',error); 

            })
        } catch (error) {
           console.log('error'.error.message); 
        }

    }

useEffect(()=>{
    fetchContactData();

},[])




   


  return (
    <div className='container'>
            <div className='container'>
                <h1>Contact List</h1>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Reason</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        data && data.map((element,index)=>(
                    <tr>
                        <td>{index+1}</td>
                        <td>{element.name}</td>
                        <td>{element.email}</td>
                        <td>{element.mobile}</td>
                        <td>{element.discription}</td>
                    </tr>

                        ))
                    }

                </tbody>
            </Table>
        </div>
  )
}

export default NewContactList
