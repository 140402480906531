import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
// import "./UpdateCategoryForm.css";
import { toast ,Bounce} from 'react-toastify';

const UpdateCategoryForm = () => {
const[catData,SetCatData]=useState('')
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [status,SetStatus]=useState('')
  const {catId}= useParams();

  // console.log(catId);
  

  const HandleChooseFile = (e) => {
    let value = e.target.files[0]
    setImage(value);
  }
 

  // const handleSumbit = async (e) => {
  //   e.preventDefault();

  //   // http://localhost:5000/api/category/add-category"


  //   axios.post('http://localhost:5000/api/category/add-category', formData, {
  //     header: {
  //       'content-type': 'multipart/form-data'
  //     }
  //   }).then(function (response) {
  //     console.log(response);

  //   }).catch((error) => {
  //     console.log(error, 'error');

  //   })

  //   navigate('/')


    
    
  // };
  const findCategoryId=(id)=>{
    axios.get(`https://tanish.xpertart.com/api/category/find-category?_id=${id}`).then((response)=>{
      // console.log(response.data.data[0]);
      SetCatData(response.data.data[0])
      setCategory(response.data.data[0].category);
      SetStatus(response.data.data[0].status);
    })


  }

const UserToken=localStorage.getItem('token')

const UpdateProduct=(e)=>{
  e.preventDefault();
  const formData = new FormData();

  formData.append('token',UserToken);
  formData.append('category', category);
  formData.append('image', image);
  formData.append('status',status);
  formData.append('_id',catId);


  axios.post(`https://tanish.xpertart.com/api/category/update-category`,formData,{

    headers:{
      'authorization':UserToken
    }
  }).then((response)=>{
console.log(response);


toast.success(response.data.message, {
  position: "top-center",
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
});

  })
}

  

  useEffect(()=>{
    if(catId){
      findCategoryId(catId)
    }
  },[catId])
  return (
    <form id='cateForm' onSubmit={UpdateProduct} encType='multipart/form-data'>
      <div>
        <label for="category name">Category name: </label>
        <input type="text" name="categoryname" required onChange={(e) => setCategory(e.target.value)} value={category}  />
        <br />
        <label for="image">Image </label>
        <input type="file" name="lastname" accept='image/' onChange={HandleChooseFile}  />

        {
          catData &&(
            <img src={catData.image} alt="category" style={{width:'30%'}} />
          )
        }
        <br />
        <div onChange={(e)=>SetStatus(e.target.value)} >
    <label htmlFor="status">status</label>
    <br />
    <input type="radio" name='status' value='Y' checked={status&&status==='Y'&& true} />YES
    <input type="radio" name='status' value='N' checked={status&&status==='N'&& true} />No
</div>



        <button type="submit">Update </button>

      </div>
    </form>


  )
}

export default UpdateCategoryForm