import React, { useState } from 'react'
import './SubCategoryForm.css'
import axios from 'axios';
import { Bounce, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const SubCategoryForm = () => {

const [name,SetName]=useState('');
const [image,SetImage]=useState('');
const[status,SetStatus]=useState('');

const navigate=useNavigate()

const HandleSubmit=(e)=>{
  e.preventDefault()
  const formData= new FormData();
  formData.append('name',name);
  formData.append('image',image);
  formData.append('status',status);

  axios.post(`https://tanish.xpertart.com/api/sub-category/add-sub-category`,formData,{
    headers:{
      'Content-Type':'multipart/form-data'
    }
  }).then((response)=>{
    console.log(response);
    toast.success(response.data.message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    })
    navigate ('/sub-cat-list')

    
  }).catch((error)=>{
    console.log(error);
    
  })

}



  return (
    <div className="App">
      <h1>Sub-Category Form</h1>
      <form className='subForm' onSubmit={HandleSubmit} encType='multipart/form-data'>
        <div>
          <label htmlFor="name">Name:</label>
          <input
          onChange={(e)=>SetName(e.target.value)} value={name}
            type="text"
            id="name"
            name="name"
          
            required
          />
        </div>
        <div>
          <label htmlFor="image">Image:</label>
          <input
          onChange={(e)=>SetImage(e.target.files[0])}
            type="file"
            id="image"
            name="image"
            accept="image/*"
            
          />
        </div>
        <div onChange={(e)=>SetStatus(e.target.status)} value={status}>
          <label htmlFor="status">Status:</label>
        
           
          
            <input type="radio" name='status' />Yes
            <br />
            <input type="radio" name='status' />No

        
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  )
}

export default SubCategoryForm
