import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useParams } from "react-router-dom";
import { Bounce, toast } from 'react-toastify';

const EditProductForm = () => {

    const { proId } = useParams()

    const [product_name, setProductName] = useState('');
    const [details, setDetails] = useState('');
    const [price, setPrice] = useState('');
    const [category, setCategoryID] = useState('');
    const [catData, SetCatData] = useState([]);
    const [rating, setRating] = useState('');
    const [image, setImage] = useState('')
    const [status, SetStatus] = useState('');
    const [feature, SetFeature] = useState('')
    const [productList, setProductList] = useState('')


    const fetchCatData = async () => {
        try {
            axios.get('https://tanish.xpertart.com/api/category/find-category')
                .then((response) => {
                    // console.log("response setData",response.data.data);
                    // setData(response.data.data)
                    SetCatData(response.data.data)
                })
                .catch((error) => {
                    console.log('error', error);

                })
        } catch (error) {
            console.log('error'.error.message);
        }

    }

    const ProductList = async (id) => {
        axios.get(`https://tanish.xpertart.com/api/products/find-Products?_id=${id}`)
            .then(async (response) => {
                console.log("response.data", response.data.product.categoryId._id);

                setProductList(response.data.product);

                await setDetails(response.data.product.details);
                await setProductName(response.data.product.product_name)
                await setPrice(response.data.product.price)
                await setCategoryID(response.data.product.categoryId._id)
                await setRating(response.data.product.rating)
                await setImage(response.data.product.image)
                await SetStatus(response.data.product.status)
                await SetFeature(response.data.product.isFeature)

            })
            .catch((error) => {
                console.log('error', error.message);
            })
    }


    useEffect(() => {
        fetchCatData();
        ProductList(proId);
    }, [proId])

    // const handleChooseFile = (e) => {
    //     let name = e.target.name;
    //     let value = e.target.files[0]
    //     setImage(value);
    // }

    // const navigate = useNavigate();
    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     const formData = new FormData();
    //     formData.append('product_name', product_name);
    //     formData.append('details', details);
    //     formData.append('price', price);
    //     formData.append('categoryId', category);
    //     formData.append('rating', rating);
    //     formData.append('image', Image);
    //     formData.append('status', status);
    //     formData.append('isFeature', feature);

    //     axios.post("https://tanish.xpertart.com/api/products/add-products", formData, {
    //         header: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     })
    //         .then(function (response) {
    //             console.log(response);

    //             alert("product added successfully")
    //             toast.success(response, {
    //                 position: "top-center",
    //                 autoClose: 4000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "light",
    //                 transition: Bounce,

    //             });

    //             setProductName('')
    //             setDetails('')
    //             setPrice('')
    //             setCategoryID('')
    //             setRating('')
    //             setImage('')
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })

    //     // navigate('');
    // }
    const userToken = localStorage.getItem('token');

    const UpdateProduct = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('token', userToken);
        formData.append('product_name', product_name);
        formData.append('details', details);
        formData.append('price', price)
        //  formData.append('categoryId',category)
        formData.append('rating', rating)
        formData.append('image', image)
        formData.append('category', category)
        formData.append('status', status)
        formData.append('feature', feature)
        formData.append('_id', proId)

        axios.post(`https://tanish.xpertart.com/api/products/update-Products`, formData, {
            headers: {
                'authorization': userToken
            }
        }).then(async (response) => {
            console.log(response.data);


            toast.success(response.data.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,

            });

            await ProductList(proId);

        }).catch((error) => {
            console.log(error);

            toast.error(error.message, {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });


        })



    }



    // console.log(productList, "productList");


    return (
        <div className="form-container">
            <h1 className='hone'>Edit Product</h1>

            <form onSubmit={UpdateProduct} encType='multipart/form-data'>

                <div className="form-group">
                    <label className='textClass' htmlFor="productName">Product Name:</label>
                    <input type="text" id="productName" name="product_Name" required onChange={(e) => setProductName(e.target.value)} value={product_name}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="productDescription">Product Details:</label>
                    <textarea id="productDescription" name="productDescription" required onChange={(e) => setDetails(e.target.value)} value={details}
                    ></textarea>
                </div>
                <div className="form-group">
                    <label htmlFor="price">Price:</label>
                    <input type="number" id="price" name="price" required onChange={(e) => setPrice(e.target.value)} value={price}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="category">Category:</label>
                    {/* <input type="text" id="category" name="category"
                        required onChange={(e) => setCategoryID(e.target.value)} value={categoryId} */}
                    {/* /> */}

                    <select name='' id='' onChange={(e) => setCategoryID(e.target.value)}>
                        <option value=''>--select categoryId--</option>
                        {catData && catData.map((element, index) => (
                            <option value={element._id}
                                selected={category === element._id ? true : false}
                                key={index}>{element.category}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="rating">Rating:</label>
                    <input type="number" id="rating" name="rating"
                        required onChange={(e) => setRating(e.target.value)} value={rating}
                    />
                </div>

                <div onChange={(e) => SetStatus(e.target.value)} >
                    <label htmlFor="status">status</label>
                    <br />
                    <input type="radio" name='status' value='Y' checked={status && status === 'Y' && true} />YES
                    <input type="radio" name='status' value='N' checked={status && status === 'N' && true} />No
                </div>



                <div onChange={(e) => SetFeature(e.target.value)}  >
                    <label htmlFor="status">Feature</label>
                    <br />
                    <input type="radio" name='feature' value='Y' checked={feature && feature === 'Y' && true} />YES
                    <input type="radio" name='feature' value='N' checked={feature && feature === 'N' && true} />No
                </div>


                <div className="form-group">
                    <label for="productImage">Product Image</label>
                    <input

                        type="file"
                        name="productImage"
                        accept="image/"
                        onChange={(e) => setImage(e.target.files[0])}

                    // onChange={handleChooseFile}
                    />
                    {
                        productList && (

                            <img src={productList.image} alt="" style={{ width: '5%' }} />
                        )
                    }
                    <h1>{Image}</h1>
                </div>
                <div className="form-group">
                    <button type="submit" >Update</button>
                </div>
            </form>
        </div>
    )
}

export default EditProductForm

