import React, { useEffect, useState } from 'react'
import './MyProduct.css'
import axios from 'axios';
import { Bounce, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const MyProduct = () => {
  const navigate = useNavigate();
  const [my_product_name, SetMy_product_name] = useState('');
  const [description, SetDescription] = useState('');
  const [ProductPrice, SetProductPrice] = useState('');
  const [rating, SetRating] = useState('');
  const [categoryId, SetCategoryId] = useState('');
  const [subcategoryId, SetSubcategoryId] = useState('');
  const [count, SetCount] = useState('');
  const [MyProductImage, SetMyProductImage] = useState('');
  const [isSpecial, SetIsSpecial] = useState('N');
  const [status, SetStatus] = useState('N');

  const [subCatData, SetSubCatData] = useState('');
  const [catData, SetCatData] = useState();


  const FindSubCatList = () => {
    axios.get('http://localhost:5000/api/sub-category/find-sub-category').then((response) => {
      // console.log(response);
      SetSubCatData(response.data.data)
    }).catch((error) => {
      console.log(error);

    })
  }


  const FindCatList = () => {
    axios.get('http://localhost:5000/api/category/find-category').then((response) => {
      // console.log(response);
      SetCatData(response.data.data)
    }).catch((error) => {
      console.log(error);

    })
  }

  useEffect(() => {
    FindCatList();
    FindSubCatList();

  }, [])
  const handleSubmit = (e) => {


    e.preventDefault();



    if (!my_product_name) {
      toast.error("Please Enter Name", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return
    }

    else if (!categoryId) {
      toast.error("Please select categoryId", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return
    }

    else if (!subcategoryId) {
      toast.error("Please select subcategoryId", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return
    }

    else if (!ProductPrice) {
      toast.error("Please Enter ProductPrice", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return
    }

    else if (!MyProductImage) {
      toast.error("Please choose Image", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return
    }

    else if (!rating) {
      toast.error("Please Enter rating", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return
    }
    else if (!count) {
      toast.error("Please Enter count", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return
    }

    else if (!isSpecial) {
      toast.error("Please chose isSpecial", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return
    }

    else if (!status) {
      toast.error("Please chose status", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return
    }
    else if (!description) {
      toast.error("Please Enter description", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return
    }





    else if (!MyProductImage) {
      toast.error("Please Enter MyProductImage", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return
    }






    const formData = new FormData();
    formData.append('my_product_name', my_product_name);
    formData.append('description', description);
    formData.append('ProductPrice', ProductPrice);
    formData.append('rating', rating);
    formData.append('categoryId', categoryId);
    formData.append('subcategoryId', subcategoryId);
    formData.append('count', count);
    formData.append('MyProductImage', MyProductImage);
    formData.append('isSpecial', isSpecial);
    formData.append('status', status);


    axios.post(`http://localhost:5000/api/my-products/add-products`, formData, {
      headers: {
        'Content-Type': 'multiPart/form-data'
      }
    }).then((response) => {
      console.log(response);

      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      navigate('/my-products-list')
      MyProduct()
    }).catch((error) => {
      console.log(error);

    })


  }



  return (
    <div className="form-container">
      <h2 className="form-title">Add my products</h2>
      <form className="product-form" onSubmit={handleSubmit} encType='multiPart/form-data'>
        <div className="form-group">
          <label className="form-label">
            Product Name:
            <input
              type="text"
              name="productName"
              onChange={(e) => SetMy_product_name(e.target.value)}
              value={my_product_name}

              className="form-input"

            />
          </label>
        </div>
        <div className="form-group" onChange={(e) => SetCategoryId(e.target.value)} >
          <label className="form-label">
            Product Category:
            {/* <input
              type="text"
              name="productCategory"

             
              className="form-input"
              
            /> */}
            <select name="" id="">
              <option value="selectOne">----selectOne---</option>

              {catData && catData.map((element, index) => (
                <option value={element._id} key={index + 1}>{element.category}</option>
              ))}
            </select>
          </label>
        </div>
        <div className="form-group" onChange={(e) => SetSubcategoryId(e.target.value)} value={subcategoryId}>
          <label className="form-label">
            Product Subcategory:

            <select name="" id="">
              <option value="selectOne">----selectOne---</option>
              {subCatData && subCatData.map((element, index) => (
                <option value={element._id} key={index + 1}>{element.name}</option>
              ))}
            </select>
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Product Price:
            <input
              onChange={(e) => SetProductPrice(e.target.value)}
              value={ProductPrice}
              type="number"
              name="productPrice"


              className="form-input"

            />
          </label>
        </div>

        <div className="form-group">
          <label className="form-label">
            Product Image:
            <input
              onChange={(e) => SetMyProductImage(e.target.files[0])}

              type="file"
              name="productImage"
              accept='image/'

              className="form-input"
            />
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Product Rating (1-5):
            <input
              onChange={(e) => SetRating(e.target.value)}
              value={rating}
              type="number"
              name="productRating"


              className="form-input"
              min="1"
              max="5"
            // step="0.1"
            />
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Product Count:
            <input
              onChange={(e) => SetCount(e.target.value)}
              value={count}
              type="number"
              name="productCount"


              className="form-input"
              min="0"
            />
          </label>
        </div>
        <div className="form-group" onChange={(e) => SetIsSpecial(e.target.value)}
          value={isSpecial}>
          <label className="form-label">
            Is Special:
            <input

              type="radio"
              value='Y'
              name="isSpecial"
              checked={isSpecial && isSpecial === "Y"}

              className="form-checkbox"
            />Y

            <input

              type="radio"
              value='N'
              name="isSpecial"
              checked={isSpecial && isSpecial === "N"}

              className="form-checkbox"
            />N
          </label>
        </div>
        <div className="form-group" onChange={(e) => SetStatus(e.target.value)} value={status}>
          <label className="form-label">
            Status:
            <select
              name="status"


              className="form-select"> 

               <option value="N">N</option>


              <option value="Y">Y</option>

            </select>
          </label>
        </div>


        <div className="form-group" onChange={(e) => SetDescription(e.target.value)} value={description}>
          <label className="form-label">
            Product Description:
            <textarea
              name="productDescription"


              className="form-textarea"
            />
          </label>
        </div>

        <button type="submit" className="form-button">Submit</button>
      </form>
    </div>)
}

export default MyProduct
