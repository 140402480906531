import React from 'react'
import "./About.css"

const About = (props) => {
  return (
    <div className="aboutHelpBox">
    <div className="container">
        <div className="about_flex">

            <div className="aboutHP">
                <div className="aboutUs">
                    <h1>About Us </h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat, nemo perferendis cum
                        natus veritatis aperiam vero ex harum magni sequi, animi minima eum dolorem tempora. Illo,
                        magni. Doloribus, sit dolore!</p>
                </div>
            </div>
            <div className="aboutImage">

            </div>
        </div>
        <hr/>
        <div className="about_flex2">

            <div className="aboutHP">
                <div className="aboutUs">
                    <h1>About Us </h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat, nemo perferendis cum
                        natus veritatis aperiam vero ex harum magni sequi, animi minima eum dolorem tempora. Illo,
                        magni. Doloribus, sit dolore!</p>
                </div>
            </div>
            <div className="aboutImage">

            </div>
        </div>
        <hr/>
        <div className="about_flex">

            <div className="aboutHP">
                <div className="aboutUs">
                    <h1>About Us </h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat, nemo perferendis cum
                        natus veritatis aperiam vero ex harum magni sequi, animi minima eum dolorem tempora. Illo,
                        magni. Doloribus, sit dolore!</p>
                </div>
            </div>
            <div className="aboutImage">

            </div>
        </div>

    </div>
</div>
  )
}

export default About
