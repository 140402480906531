import React, { useState } from 'react'
import './AddSliderForm.css'
import axios from 'axios';
import { Bounce, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AddSliderForm = () => {
    const [image, SetImage] = useState('');
    const [name, SetName] = useState('');
    const [status, SetStatus] = useState('N');

    const navigate = useNavigate();
    const HandleChooseFile = (e) => {
        let value = e.target.files[0]
        SetImage(value)
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('image', image);
    formData.append('status',status);
    const handleUpload = (e) => {
        e.preventDefault();
        axios.post(`https://tanish.xpertart.com/api/slider/add-slider`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            console.log(response);

            // alert('slider image uploaded successfully')

            toast.success(response.data.message, {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            navigate('/slider-list')

        }).catch((error) => {
            console.log(error);
            toast.error(error.message, {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            // alert(error.message)
        })

        SetImage('');
        SetName('');
        SetStatus('');
    }


    return (
        <div>
            <form id='cateForm' onSubmit={handleUpload} encType='multipart/form-data'>
                <div>
                    <label for="category name">Image name: </label>
                    <input type="text" name="imagename" required onChange={(e) => SetName(e.target.value)} value={name} />
                    <br />
                    <label for="image">Image </label>
                    <input type="file" name="lastname" accept='image/' onChange={HandleChooseFile} />
                    <br />
                    <div onChange={(e) => SetStatus(e.target.value)} value={status} >
                        <label htmlFor="status">status</label>
                        <br />
                        <input type="radio" checked={status && status === "Y"} name='status' value="Y" />YES
                        <input type="radio" checked={status && status === "N"} name='status' value="N" />No
                    </div>



                    <button type="submit">Upload Image </button>

                </div>
            </form>

        </div>
    )
}

export default AddSliderForm
