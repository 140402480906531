import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './ProductForm.css'
import { Bounce, toast } from 'react-toastify';

const ProductForm = () => {
const userToken=localStorage.getItem('token')

    const [product_name, setProductName] = useState('');
    const [details, setDetails] = useState('');
    const [price, setPrice] = useState('');
    const [categoryId, setCategoryID] = useState('');
    const [rating, setRating] = useState('');
    const [image, setImage] = useState('')
 const[status,SetStatus]=useState('N');
 const[feature,SetFeature]=useState('N')

    const [categoryyList, setCategoryyList] = useState([])
    console.log();

    const navigate=useNavigate();

    const categoryList = async () => {
        axios.get('https://tanish.xpertart.com/api/category/find-category')
            .then((response) => {
                console.log(response);
                
                setCategoryyList(response.data.data);

            })
            .catch((error) => {
                console.log('error', error.message);

            })
    }

    useEffect(() => {
        categoryList();
    }, [])

    const handleChooseFile = (e) => {
        let value = e.target.files[0]
        setImage(value);
    }

    // const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();


        if(!product_name){
            toast.error("Please Enter product-Name", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return
          }
          

          if(!details){
            toast.error("Please Enter product_details", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return
          }

          if(!price){
            toast.error("Please Enter product_price", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return
          }

          if(!categoryId){
            toast.error("Please select product_categoryId", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return
          }

          if(!rating){
            toast.error("Please Enter product_rating", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return
          }

          if(!image){
            toast.error("Please choose product_image", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return
          }


          if(!status){
            toast.error("Please choose product_status", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return
          }

          if(!feature){
            toast.error("Please choose product_feature", {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return
          }

        const formData = new FormData();
        formData.append('product_name', product_name);
        formData.append('details', details);
        formData.append('price', price);
        formData.append('categoryId', categoryId);
        formData.append('rating', rating);
        formData.append('image', image);
        formData.append('status', status);
        formData.append('isFeature', feature);

        

        axios.post("https://tanish.xpertart.com/api/products/add-products", formData, {
            headers: {
                'authorization':userToken
            }

        })
            .then(function (response) {
                console.log(response);

                alert("product added successfully")
                toast.success(response.data.message, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,

                });
                navigate ('/product-list')

                setProductName('')
                setDetails('')
                setPrice('')
                setCategoryID('')
                setRating('')
                setImage('')
            })
            .catch((error) => {
                console.log(error);
            })

        // navigate('');
    }


    useEffect(() => {

    }, [])

    return (
        <div className="form-container">
            <h1 className='hone'>Add Product</h1>

            <form className='form-main' onSubmit={handleSubmit}>
                <div className="form-group">
                    <label className='textClass' htmlFor="productName">Product Name:</label>
                    <input type="text" id="productName" name="product_Name"  onChange={ (e) => setProductName(e.target.value)} value={product_name}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="productDescription">Product Details:</label>
                    <textarea id="productDescription" name="productDescription"  onChange={(e) => setDetails(e.target.value)} value={details}
                    ></textarea>
                </div>
                <div className="form-group">
                    <label htmlFor="price">Price:</label>
                    <input type="number" id="price" name="price"  onChange={(e) => setPrice(e.target.value)} value={price}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="category">Category:</label>
                    {/* <input type="text" id="category" name="category"
                         onChange={(e) => setCategoryID(e.target.value)} value={categoryId} */}
                    {/* /> */}

                    <select name='' id='' onChange={(e) => setCategoryID(e.target.value)} >
                    <option value=''>--select categoryId--</option>
                    {
                            categoryyList && categoryyList.map((element, index) => (
                            
                                <option value={element._id} key={index+1}>{element.category}</option>

                            ))
                        }

                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="rating">Rating:</label>
                    <input type="number" id="rating" name="rating"
                         onChange={(e) => setRating(e.target.value)} value={rating}
                    />
                </div>

<div onChange={(e)=>SetStatus(e.target.value)} value={status}>
    <label htmlFor="status">status</label>
    <br />
    <input type="radio" name='status' value='Y'/>YES
    <input type="radio" name='status'value='N' />No
</div>



<div onChange={(e)=>SetFeature(e.target.value)} value={feature} >
    <label htmlFor="status">Feature</label>
    <br />
    <input type="radio" name='feature' value='Y' />YES
    <input type="radio"name='feature' value='N' />No
</div>


                <div className="form-group">
                    <label for="productImage">Product Image</label>
                    <input
                        type="file"
                        name="productImage"
                        accept="image/"
                        
                        //   onChange={(e) => setImage(e.target.value)} value={image}
                        onChange={handleChooseFile}
                    />
                </div>
                <div className="form-group">
                    <button type="submit" >Submit</button>
                </div>
            </form>
        </div>
    )
}

export default ProductForm

