import React, { useContext, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import "./Signin.css"
import { Bounce, toast } from 'react-toastify';
import { AuthContext, } from '../Store/Auth';
// import {CookiesProvider, useCookies } from 'react-cookie';

const Signin = () => {
    const { loginUser } = useContext(AuthContext)

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // console.log(email);
    // console.log(password);

    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        // if (!email) {
        //     toast.error('please enter email', {
        //         position: "top-center",
        //         autoClose: 3000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "light",
        //         transition: Bounce,
        //     });
        //     return

        // }


        // if (!password) {
        //     toast.error('please enter your password', {
        //         position: "top-center",
        //         autoClose: 3000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "light",
        //         transition: Bounce,
        //     });
        //     return
        // }

        if (!email || !password) {
            const Email = {
                field: 'email ',
                state: email
            };
            const Password = {
                field: 'password ',
                state: password
            };

            const array = [Email, Password];
            const filteredArray = array.filter((data) => data.state === '');
            {
                const find = filteredArray.map((element, index) => {
                    return element.field
                })
                toast(`🦄 please enter : ${find}`, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
            return
        }

        const content = await loginUser(email, password)
        console.log("content", content);

        if (content.success === false) {
            toast.error(content.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return
        }
        if (content.data.success) {
            toast.success(content.data.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            navigate("/");
            return;
        }
        else {
            toast.error(content.data.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return;
        }

    }

    return (
        <div id="signIn">
            <div className="container">
                <div className="login-form_box">
                    <div className="login-form">
                        <center><u><h2 style={{ color: "#00b8ff" }}>Login nOw </h2></u></center>
                        <form onSubmit={handleLogin} mathod="post" action="#">
                            <div className="Form_container">
                                <label htmlFor="email">E-mail</label><br />
                                <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} /><br />
                                <label htmlFor="password">Password</label><br />
                                <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} /><br />
                                <div className="forgotPass">
                                    <Link to="/forgot-pass">Forgot password ?</Link>
                                </div>
                                <br />

                                <div className="login-button" align="center">
                                    <button type="submit">Sign in</button>
                                </div>
                                <br />
                                <div className="login_button" align="right">
                                    <Link to="/sign-up">Sign Up</Link>
                                </div>


                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signin
