import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "./TreandinProduct.css";
const TreandingProduct = () => {
    const [data,setData]=useState('')

    const findproduct=()=>{
       axios.get(`https://tanish.xpertart.com/api/products/find-Products?status==Y`).then((response)=>{
setData(response.data.product)

       }).catch((error)=>{
        console.log(error);
        
       })

    }
    useEffect(()=>{
        findproduct()
    },[])

    const filterData=data&&data.filter(element=>element.isFeature=='Y')

  return (
    
        <section id="trandingProuctPage">
            <div className="container">
                <div className="Trainding-Product_heading">
                    <h1>Trending products 📈 <i className="fa-regular fa-face-kiss-wink-heart"></i></h1>
                </div>
                <div className="Tranding-produtFlex">
                    {filterData&&filterData.map((element,index)=>(
                          
                        <div className="trandin-product">
                            <Link to={`/product-detail/${element._id}`}>
                                <div className="produtImage">
                                    <img src={element.image} alt="proImage" />
                                </div>
                            </Link>
                            <div className="tranding-produtData">
                                <h1>{element.product_name}</h1>
                                <h2>${element.price}</h2>
                                <Link to={`/product-detail/${element._id}`}><i className="fa-solid fa-cart-shopping"></i> Buy Now</Link>
                            </div>
                        </div>
                         ))}
               
                </div>

            </div>
        </section>
    
  )
}

export default TreandingProduct


/* product --START-- */

