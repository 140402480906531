import React from 'react';
import './PaymentSuccessfully.css';
import { Alert } from 'react-bootstrap';

const Success = ({success}) => { 
  const redirectToHome = () => {
    window.location.href = '/';
  };

  return (
    <div className="payment-success-container">
      <div className="payment-success-content">
        {/* Success Icon */}
        <div className="payment-success-icon">
          <span role="img" aria-label="checkmark">✅</span>
        </div>

        {/* Success Message */}
        <h1>Payment Successful!</h1>
        <p>Your payment has been successfully processed. Thank you for your purchase!</p>
        {success && <Alert variant="success" className="mt-3">{success}</Alert>}
        {/* Optional Button to Redirect */}
        <button className="redirect-btn" onClick={redirectToHome}>
          Go to Home
        </button>
      </div>
    </div>
  );
};

export default Success;

