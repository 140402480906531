
import axios from 'axios';
import { Bounce, toast } from 'react-toastify';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'


const EditMyProduct = () => {

  const navigate=useNavigate();


  const [my_product_name, SetMy_product_name] = useState('');
  const [description, SetDescription] = useState('');
  const [ProductPrice, SetProductPrice] = useState('');
  const [rating, SetRating] = useState('');
  const [categoryId, SetCategoryId] = useState('');
  const [subcategoryId, SetSubcategoryId] = useState('');
  const [count, SetCount] = useState('');
  const [MyProductImage, SetMyProductImage] = useState('');
  const [isSpecial, SetIsSpecial] = useState('');
  const [status, SetStatus] = useState('');

  const [subCatData, SetSubCatData] = useState('');
  const [catData, SetCatData] = useState([]);

  const [MyList, SetMyList] = useState({});

  // console.log('MyList',MyList);
  

  const FindSubCatList = () => {
    axios.get('http://localhost:5000/api/sub-category/find-sub-category').then((response) => {
      // console.log("SetSubCatData",response.data.data);
      SetSubCatData(response.data.data)
    }).catch((error) => {
      console.log(error);

    })
  }


  const FindCatList = () => {
    axios.get('http://localhost:5000/api/category/find-category').then((response) => {
      // console.log(response.data.data);
      SetCatData(response.data.data)
    }).catch((error) => {
      console.log(error);

    })
  }

  const { myId } = useParams()
  console.log(myId);
  
  const getProductById = async (id) => {
    axios.get(`http://localhost:5000/api/my-products/find-products?_id=${id}`).then((response) => {
      console.log(response.data.product);
      SetMyList(response.data.product);
      SetMy_product_name(response.data.product.my_product_name);
      SetDescription(response.data.product.description);
      SetProductPrice(response.data.product.ProductPrice);
      SetRating(response.data.product.rating);
      SetCategoryId(response.data.product.categoryId._id);
      SetSubcategoryId(response.data.product.subcategoryId._id);
      SetCount(response.data.product.count);
      SetMyProductImage(response.data.product.MyProductImage);
      SetIsSpecial(response.data.product.isSpecial);
      SetStatus(response.data.product.status);



    }).catch((error)=>{
      console.log(error.message);
      
    })
  }

  useEffect(() => {
    FindCatList();
    FindSubCatList();
    getProductById(myId)
  }, [myId])

  const useToken=localStorage.getItem('token')
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('token',useToken);
    formData.append('my_product_name', my_product_name);
    formData.append('description', description);
    formData.append('ProductPrice', ProductPrice);
    formData.append('rating', rating);
    formData.append('categoryId', categoryId);
    formData.append('subcategoryId', subcategoryId);
    formData.append('count', count);
    formData.append('MyProductImage', MyProductImage);
    formData.append('isSpecial', isSpecial);
    formData.append('status', status);
    formData.append('_id', myId);

    axios.post(`http://localhost:5000/api/my-products/update-products`, formData, {
      headers: {
      'authorization':useToken
      }
    }).then(async(response) => {
      // console.log(response);

      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      navigate ('/my-products-list')

    await getProductById(myId);

    }).catch((error) => {
      console.log(error);
      toast.error(error.message, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    })


  }



  return (
    <div className="form-container">
      <h2 className="form-title"> Edit My products</h2>
      <form className="product-form" onSubmit={handleSubmit} encType='multiPart/form-data'>
        <div className="form-group">
          <label className="form-label">
            Product Name:
            <input
              type="text"
              name="productName"
              onChange={(e) => SetMy_product_name(e.target.value)}
              value={my_product_name}

              className="form-input"
              
            />
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Product Category:
            {/* <input
                  type="text"
                  name="productCategory"                 
                  className="form-input"
                  required
                /> */}
            <select name="" id="" onChange={(e)=>SetCategoryId(e.target.value)} >
              {catData && catData.map((element, index) => (
                <option value={element._id}
                selected={categoryId===element._id ?true:false}
                key={index + 1}>{element.category}</option>
              ))}
            </select>
          </label>
        </div>
        <div className="form-group" >
          <label className="form-label">
            Product Subcategory:
            {/* <input
                  type="text"
                  name="productSubcategory"
            
                 
                  className="form-input"
                /> */}
            <select name="" id="" onChange={(e)=>SetSubcategoryId(e.target.value)} value={subcategoryId}>
              {subCatData && subCatData.map((element, index) => (
                <option value={element._id}
                selected={subcategoryId===element._id ?true:false} 
                key={index + 1}>{element.name}</option>
              ))}
            </select>
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Product Price:
            <input
              onChange={(e) => SetProductPrice(e.target.value)}
              value={ProductPrice}
              type="number"
              name="productPrice"


              className="form-input"
              required
            />
          </label>
        </div>
        <div className="form-group" >
          <label className="form-label">
            Product Description:
            <textarea
              name="productDescription"
              className="form-textarea"
              onChange={(e) => SetDescription(e.target.value)} 
              value={description}
            />
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Product Image:
            <input
              onChange={(e) => SetMyProductImage(e.target.files[0])}

              type="file"
              name="productImage"
              accept='image/'

              className="form-input"
            />
{
  MyList&&(
    <img src={MyList.image} alt="" style={{width:'10%'}}/>
  )
}

          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Product Rating (1-5):
            <input
              onChange={(e) => SetRating(e.target.value)}
              value={rating}
              type="number"
              name="productRating"


              className="form-input"
              min="1"
              max="5"
              step="0.1"
            />
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Product Count:
            <input
              onChange={(e) => SetCount(e.target.value)}
              value={count}
              type="number"
              name="productCount"


              className="form-input"
              min="0"
            />
          </label>
        </div>
        <div className="form-group" onChange={(e) => SetIsSpecial(e.target.value)}
          value={isSpecial}>
          <label className="form-label">
            Is Special:
            <input

              type="radio"
              value='Y'
              checked={isSpecial&&isSpecial === 'Y'&&true}
              name="isSpecial"


              className="form-checkbox"
            />Y

            <input

              type="radio"
              value='N'
              checked={isSpecial&&isSpecial ==='N'&&true}
              name="isSpecial"


              className="form-checkbox"
            />N
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Status:
            <select
             onChange={(e) => SetStatus(e.target.value)} value={status}
              name="status"


              className="form-select"
            >
              <option value="--Select-one--">select</option>
              <option value="Y">Y</option>
              <option value="N">N</option>
            </select>
          </label>
        </div>
        <button type="submit" className="form-button">Update</button>
      </form>
    </div>

  )

}

export default EditMyProduct
