import React from 'react'
import { Spinner, } from 'react-bootstrap'

const Loading = ({show}) => {
  return show && (
    <div className='text-center p-4 h-90 w-90'>
      {/* <Spinner size='lg'/> */}
      {/* <MoonLoader size='lg'/> */}
      <Spinner animation="border" variant="success"  />
     {/* <TailSpin
     align-item='Center'
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      
    />; */}

    </div>
  )
}

export default Loading
