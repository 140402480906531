import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table';
import { FaTrash, FaEdit, FaToggleOff } from "react-icons/fa";
import { IoToggleOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';

const ProductList = () => {


    const [data, setData] = useState([])
    const fetchProductData = () => {
        try {

            axios.get("https://tanish.xpertart.com/api/products/find-Products")
                .then((response) => {
                    console.log("response", response.data.product);
                    setData(response.data.product)
                })
                .catch((error) => {
                    console.log("error", error);
                })
        } catch (error) {
            console.log("error", error.message);
        }
    }

    useEffect(() => {
        fetchProductData();
    }, [])

    const UpdateStatus = async (id) => {
        try {
            axios.get(`https://tanish.xpertart.com/api/products/Update-status?_id=${id}`).then(async (response) => {
                // console.log(response.data.message);
                const confirm=window.confirm('Do you really want to change product status ?')
                if(confirm){
                toast.success(response.data.message, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                await fetchProductData()
            }
            }).catch((error) => {
                console.log('error', error);

                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });

            })
        } catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }

    const UpdateFeature = async (id) => {
        try {
            axios.get(`https://tanish.xpertart.com/api/products/update-feature?_id=${id}`).then(async (response) => {
                // console.log(response.data.message);
                const confirm=window.confirm('Do you really want to change product status ?')
                if(confirm){
                toast.success(response.data.message, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                await fetchProductData()
            }
            }).catch((error) => {
                console.log('error', error);

                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });

            })
        } catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }

const useToken=localStorage.getItem('token')

const deleteProduct=async(id)=>{

    const formData = new FormData();
    formData.append("_id",id)
    axios.post(`https://tanish.xpertart.com/api/products/delete-Products`,formData,{
        headers:{
            "authorization":useToken
        }
    }).then(async(response)=>{

        const confirm=window.confirm('Do you really want to delete product?')

        if(confirm){

        

        toast.success(response.data.message, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        await fetchProductData()
        }
    }).catch((error)=>{
        console.log(error);
        console.log(error);
        
    })
}

    return (


        <div className="container">
            <div className="container">
                <h1>Product List</h1>
            </div>
            <Table striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>ProductName</th>
                        <th>categoryName</th>
                        <th>Image</th>
                        <th>Price</th>
                        <th>Feature</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.map((element, index) => (
                        <tr>
                            <td>{index + 1}</td>
                            <td>{element.product_name}</td>
                            <td>{element.categoryId.category}</td>

                            <td className='productImage'><img src={element.image} alt={element.product} /></td>
                            <td>{element.price}</td>
                            <td>
                                {
                                    element.isFeature && element.isFeature === 'Y' ? (<IoToggleOutline style={{ color: 'green' }} onClick={() => UpdateFeature(element._id)} />
                                    ) : (<FaToggleOff style={{ color: "red" }} onClick={() => UpdateFeature(element._id)} />)
                                }
                            </td>

                            <td>
                                <FaTrash title='DELETE' onClick={()=>deleteProduct(element._id)} />
                                <Link to={`/edit-product-form/${element._id}`}><FaEdit title='EDIT✍️' /></Link>
                                {
                                    element.status && element.status === 'Y' ? (<IoToggleOutline style={{ color: 'green' }} onClick={() => UpdateStatus(element._id)} />
                                    ) : (<FaToggleOff style={{ color: "red" }} onClick={() => UpdateStatus(element._id)} />)
                                }
                            </td>

                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default ProductList
