
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import Success from './PaymentSuccessfully';

export default function CheckoutForm({ clientSecret, totalPrice, setNextPage, totalQuantity }) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [deleveryDetails, setDeleveryDetails] = useState('');

  const navigate = useNavigate();
  const token = localStorage.getItem('token')

  const [orders, setOrders] = useState([]);

  const getOrders = async () => {
    try {
      const res = await axios.get('https://tanish.xpertart.com/api/cart/getcart', {
        headers: {
          'authorization': token
        }
      })
      setOrders(res.data.cartData);
    } catch (error) {
      console.log(error);

    }
  }

  useEffect(() => {
    getOrders();
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return; // Stripe.js has not yet loaded.
    }

    const cardElement = elements.getElement(CardElement);

    const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (stripeError) {
      setError(stripeError.message);
      setSuccess(null);
    } else {
      // Payment succeeded!
      setSuccess(`Payment succeeded! PaymentIntent ID: ${paymentIntent.id}`);
      setPaymentSuccess(true);
      const payLoad = {
        paymentIntent: paymentIntent.id,
        orderItems: orders,
        paymentStatus: "success",
        paymentMode: "Online",
        totalAmount: totalPrice,
        deleveryDetails: deleveryDetails,
        clientSecret: clientSecret
      }
      axios.post('https://tanish.xpertart.com/api/update-payment-status', payLoad, {
        headers: {
          'Authorization': token
        }
      })
        .then((response) => {
          setError(null);
          setPaymentSuccess(true)
          toast.success("Product Ordered Successfully. Shop More >", {
            position: "bottom-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          return;
        })
        .catch((error) => {
          console.log(error.message);
          return;
        })
    }
  };

  const handlBack = () => {
    setNextPage(false);
  }

  return (

    (paymentSuccess) ? (<Success success={success} />) : (
      < Card className="p-4" >
        <Card.Body>
          <h3 className="text-center mb-4">Payment</h3>
          <h4 className="text-center mb-4">Total Amount: {totalPrice}</h4>
          <h4 className="text-center mb-4">Total Item: {totalQuantity}</h4>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Card Details</Form.Label>
              <CardElement className="form-control" />
            </Form.Group>
            <Button
              type="submit"
              variant="primary"
              disabled={!stripe}
              className="w-100 mb-2"
            >
              Pay
            </Button>
          </Form>
          {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
          {success && <Alert variant="success" className="mt-3">{success}</Alert>}
          <Button
            variant="secondary"
            onClick={handlBack}
            className="w-100 mt-3"
          >
            Back
          </Button>
        </Card.Body>
      </Card >)

  );
}