import React from 'react'
import "./Footer.css"
import { Link } from 'react-router-dom'


const Footer = () => {
  return (
    <footer>
    <div className="container">
        <div className="footer">
            <table>
                <thead>
                    <tr className="head">
                        <th><span>About</span></th>
                        <th><span>Group Companies</span></th>
                        <th><span>Help</span></th>
                        <th><span>Mail Us</span></th>
                        <th><span>Address</span></th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><Link to="#">Contact Us</Link></td>
                        <td><Link to="#">Myntra</Link></td>
                        <td><Link to="#">Payments</Link></td>
                        <td><Link to="#">Cancellatio & return</Link></td>
                        <td><Link to="#">Flipkart Internet Private Limited,</Link></td>
                    </tr>
                    <tr>
                        <td><Link to="#">About Us</Link></td>
                        <td><Link to="#">easyTOshop</Link></td>
                        <td><Link to="#">Shipping</Link></td>
                        <td><Link to="#">Terms of use</Link></td>
                        <td><Link to="#"> Buildings Alyssa, Begonia &</Link></td>
                    </tr>
                    <tr>
                        <td><Link to="#">Careers</Link></td>
                        <td><Link to="#">Cleartrip</Link></td>
                        <td><Link to="#">Cancellatio & return</Link></td>
                        <td><Link to="#">Security</Link></td>
                        <td><Link to="#">Clove Embassy
                                Tech Village,</Link></td>
                    </tr>
                    <tr>
                        <td><Link to="#">Flipcart Stories</Link></td>
                        <td><Link to="#">Shopsy</Link></td>
                        <td><Link to="#">FAQ</Link></td>
                        <td><Link to="#">Privacy</Link></td>
                        <td><Link to="#"> Outer Ring Road,</Link></td>
                    </tr>
                    <tr>
                        <td><Link to="#">Press</Link></td>
                        <td><Link to="#"></Link></td>
                        <td><Link to="#">Report Infrigment</Link></td>
                        <td><Link to="#">Sitemap</Link></td>
                        <td><Link to="#">Devarabeesanahalli Village, </Link></td>
                    </tr>

                    <tr>
                        <td><Link to="#"></Link></td>
                        <td><Link to="#"></Link></td>
                        <td><Link to="#"></Link></td>
                        <td><Link to="#">Grievence Redressal</Link></td>
                        <td><Link to="#">Bengaluru, 560103,</Link></td>
                    </tr>

                    <tr>
                        <td><Link to="#"></Link></td>
                        <td><Link to="#"></Link></td>
                        <td><Link to="#"></Link></td>
                        <td><Link to="#">EPR Complience</Link></td>
                        <td><Link to="#">Karnataka, India </Link></td>
                    </tr>
                </tbody>
            </table>
            <div className="visits">
                <div>
                    <h3><i className="fa-brands fa-sellsy"></i> Became a seller</h3>
                </div>
                <div>
                    <h3><i className="fa-solid fa-rectangle-ad"></i> Advertise</h3>
                </div>
                <div>
                    <h3><i className="fa-solid fa-gifts"></i> Gift Card</h3>
                </div>
                <div>
                    <h3><i className="fa-brands fa-servicestack"></i> Help center</h3>
                </div>
                <div>
                    <h3><i className="fa-regular fa-copyright"></i> 2022-2024 MoncKTon.com</h3>
                </div>

            </div>
        </div>
    </div>
</footer>
  )
}

export default Footer
