
import React, { useState } from "react";
import "./ContactUs.css";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [discription, setdiscription] = useState("");

  const navigate = useNavigate();
  const handleSumbit = async (e) => {
    e.preventDefault();


    if (!name || !email || !mobile || !discription) {
      const Name = {
        field: 'name ',
        state: name
      };

      const Email = {
        field: 'email ',
        state: email
      };
      const Mobile = {
        field: 'mobile ',
        state: mobile
      };

      const Description = {
        field: 'description ',
        state: discription
      }

      const array = [Name, Email, Mobile, Description];
      const filteredArray = array.filter((data) => data.state === '');
      {
        const find = filteredArray.map((element, index) => {
          return element.field
        })
        toast(`🦄 please enter : ${find}`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
      return
    }

    const rawResponse = await fetch("https://tanish.xpertart.com/api/contact", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, mobile, discription }),
    });

    const content = await rawResponse.json();
    console.log(content);

    if (content.success) {
      toast.success(content.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      navigate("/");
    } else {
      console.log(content)
      toast.error(content.message.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  return (
    <div className="form_box1">
      <div className="form">
        <form className="fform" onSubmit={handleSumbit}>
          <div className="Form_container">
            <div className="details">
              <div className="FName">
                <label htmlFor="name">Name</label>
                <br />
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}

                  value={name}
                />
              </div>
              <div className="Email">
                <label htmlFor="email">E-mail</label>
                <br />
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}

                  value={email}
                />
              </div>
              <div className="mobile">
                <label htmlFor="number">Mobile No.</label>
                <br />
                <input
                  type="tel"
                  max={10}
                  maxLength={10}
                  minLength={10}
                  onChange={(e) => setMobile(e.target.value)}

                  value={mobile}
                />
              </div>

              <div className="descrip">
                <label htmlFor="discription">discription</label>
                <br />
                <textarea
                  type="text"
                  name="discription"
                  id="discription"
                  onChange={(e) => setdiscription(e.target.value)}

                  value={discription}
                ></textarea>
              </div>
            </div>

            <br />

            <div className="buttons" align="center">
              <button type="submit">Contact </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Contact;