import React, { useContext, useEffect, useState } from 'react';
import './Cart.css'; // Import the CSS file
import { FaRegTrashAlt } from "react-icons/fa";
import { FiMinus } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import axios from 'axios';
import { AuthContext } from '../Store/Auth';
import { Bounce, toast } from 'react-toastify';
import Payment from '../Payment';


const Cart = () => {

    const { addToCart, removeCart } = useContext(AuthContext)
    const [nextPage, setNextPage] = useState(false);

    const incress = async (id) => {
        const data = {
            productId: id,
            symbol: '+'
        }
        const response = await addToCart(data);

        if (response.data.success) {
            console.log(response.data);
            Viewcart();
            return
        } else {
            // console.log(error.message);
            return
        }


    }

    const dicress = async (id) => {
        const userToken = localStorage.getItem('token')
        const data = {
            productId: id,
            symbol: '-'
        }
        const response = await addToCart(data);

        // console.log(" cart", response);
        if (response.data.success) {
            console.log(response.data);
            Viewcart();
            return
        } else {
            // console.log(error.message);
            return
        }


    }


    const RemoveCart = async (id) => {
        //    const formData=new FormData(); 
        //    formData.append('id',id);
        const data = {
            _id: id
        }
        const response = await removeCart(data);
        if (response.data) {
            console.log(response);

            toast.success(`🦄 ${response.data.message}`, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });

            await Viewcart()
            return
        } else {
            console.log(response.message);
            return
        }
    }


    const handlePayment = () => {
        setNextPage(true);
    }


    const [data, SetData] = useState([])
    const token = localStorage.getItem('token')
    const Viewcart = () => {

        axios.get('https://tanish.xpertart.com/api/cart/getcart', {
            headers: {
                'authorization': token
            }
        }).then((response) => {
            console.log(response);
            SetData(response.data.cartData)

        }).catch((error) => {
            console.log(error);

        })
    }

    useEffect(() => {
        Viewcart()
    }, [])

    const totalCount = data && data.reduce((total, item) => total + item.quantity * item.productId.price, 0);

    const totalQuantity = data && data.reduce((total, item) => total + item.quantity , 0);


    return (


        (nextPage)
        ? ( <Payment  setNextPage={setNextPage} totalPrice={totalCount} totalQuantity={totalQuantity}/>        ) 
        : (<div class="container mt-5 mb-5">
            <div class="d-flex justify-content-center row">
                <div class="col-md-8">
                    <div class="p-2">
                        <h4>Shopping cart</h4>
                        <div class="d-flex flex-row align-items-center pull-right"><span class="mr-1">Sort by:</span><span class="mr-1 font-weight-bold">Price</span><i class="fa fa-angle-down"></i></div>
                    </div>



                    {data && data.map((element, index) => (
                        <div class="d-flex flex-row justify-content-between align-items-center p-2 bg-white mt-4 px-3 rounded">
                            <div class="mr-1"><img alt='pic' class="rounded" src={`https://tanish.xpertart.com/uploads/${element.productId.image}`} style={{ height: '68px', width: '57px' }} /></div>
                            <div class="d-flex flex-column align-items-center product-details"><span class="font-weight-bold">{element.productId.product_name}</span>
                                {/* <div class="d-flex flex-row product-desc">
                        <div class="size mr-1"><span class="text-grey">Size:</span><span class="font-weight-bold">&nbsp;M</span></div>
                        <div class="color"><span class="text-grey">Color:</span><span class="font-weight-bold">&nbsp;Grey</span></div>
                    </div> */}
                            </div>
                            <div class="d-flex flex-row align-items-center qty"><FiMinus onClick={() => dicress(element.productId._id)} style={{ marginRight: "5px", color: "red" }} />
                                <h5 style={{ color: "black" }} class="text-grey mt-1 mr-1 ml-1">{element.quantity}</h5><FaPlus onClick={() => incress(element.productId._id)} style={{ marginLeft: "5px", color: "green" }} /></div>
                            <div>
                                <h5 class="text-grey">${element.productId.price * element.quantity}</h5>
                            </div>
                            <div class="d-flex align-items-center"><FaRegTrashAlt onClick={() => RemoveCart(element._id)} style={{ color: "red" }} /></div>
                        </div>
                    ))}


                    <div class="d-flex flex-row align-items-center mt-3 p-2 bg-white rounded"><p className='form-control border-0 gift-card'>Total Amount</p><span className='btn btn-outline-warning btn-sm ml-2'>{totalCount}</span></div>
                    <div class="d-flex flex-row align-items-center mt-3 p-2 bg-white rounded"><button class="btn btn-warning btn-block btn-lg ml-2 pay-button" type="button" onClick={handlePayment}>Proceed to Pay</button></div>
                </div>
            </div>
        </div>)
    );
};

export default Cart;