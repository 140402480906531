import React, { useState } from 'react'
import { toast,Bounce } from 'react-toastify';
import { Link,useNavigate } from "react-router-dom"
import "./Signup.css"

const Signup = (props) => {

    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [mobile, setMobile] = useState("");
    console.log("name", name);
    console.log("email", email);
    console.log("password", password);
    console.log("mobile", mobile);


    const handleSubmitForm = async (e) => {
        e.preventDefault();

        // if(!name){
        //     toast.error("Please Enter Name", {
        //         position: "top-center",
        //         autoClose: 4000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "light",
        //         transition: Bounce,
        //     });
        //     return
        // }

        // if(!email){
        //     toast.error("Please Enter email", {
        //         position: "top-center",
        //         autoClose: 4000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "light",
        //         transition: Bounce,
        //     });
        //     return
        // }

        // if(!password){
        //     toast.error("Please Enter password", {
        //         position: "top-center",
        //         autoClose: 4000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "light",
        //         transition: Bounce,
        //     });
        //     return
        // }

        // if(!mobile){
        //     toast.error("Please Enter mobile", {
        //         position: "top-center",
        //         autoClose: 4000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "light",
        //         transition: Bounce,
        //     });
        //     return
        // }
        

        if(!name|| !email || !mobile ||!password){
            const Name={
              field:'name ',
              state:name
            };
          
            const Email={
              field:'email ',
          state:email
            };
            const Mobile={
              field:'mobile  ',
              state:mobile
            };
           
            const Password={
           field:'password ',
           state:password
            }
          
            const array=[Name,Email,Password,Mobile];
            const filteredArray=array.filter((data)=>data.state==='');
            {
              const find=filteredArray.map((element,index)=>{
                return element.field
              })
            toast(`🦄 please enter : ${find}`, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              });
            }
            return
          }


        const rawResponse = await fetch(
            "https://tanish.xpertart.com/api/user/registration",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ name, mobile, email, password }),
            }
        );

        const content = await rawResponse.json();

        if (content.success) {
            toast.success(content.message);
            navigate("/sign-in");
        } else {
            toast.error(content.message);
            return;
        }

        console.log("Accomodiations Submitted Successfully");
    }

    return (
        <div id="signUp">
            <div className="container">
                <div className="form_box">
                    <div className="form">
                        <center><u><h2 style={{color:"#00b8ff"}}>Register Yourself AT  IMONA'S</h2></u></center>
                        <form onSubmit={handleSubmitForm} method='post' action='#'>
                            <div className="Form_container">
                                <label htmlFor="name">Name</label><br />
                                <input type="text" onChange={(e) => setName(e.target.value)} value={name} /><br />
                                <label htmlFor="email">E-mail</label><br />
                                <input type="email" onChange={(e) => setEmail(e.target.value)} value={email}  /><br />
                                <label htmlFor="password">Password</label><br />
                                <input type="password" onChange={(e) => setPassword(e.target.value)} value={password}  /><br />
                                <label htmlFor="mobile">Mobile No.</label><br />
                                <input type="tel" maxLength={10} onChange={(e) => setMobile(e.target.value)} value={mobile}  /><br />
                                <div className="forgotPass">
                                    <Link to="/forgot-pass">Forgot password ?</Link>
                                </div>
                                <br />
                                <div className="checkbox">
                                    <input type="checkbox" id="cookies" name="cookies" value="cookies" />Accept all cookies &
                                    conditions
                                </div>
                                <h4 className="color_h4"><i className="fa-solid fa-check"></i> Please accept all conditions and cookies to
                                    look forword.</h4>

                                <div className="login_button" align="left">
                                    <Link to="/sign-in">Login</Link>
                                </div>


                                <div className="buttons" align="right">
                                    <button type="reset">reset</button>
                                    <button type="submit">Submit</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup
