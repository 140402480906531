import React, { useState } from 'react'
import './AddGallery.css';
import axios from 'axios';
import { Bounce, toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';
const AddGallery = () => {

    const [galleryName,SetGalleryName]=useState('');
    const [galleryImage,SetGalleryImage]=useState('');
    const [status,SetStatus]=useState('No')

const navigate=useNavigate()

    const formData = new FormData();
  formData.append('galleryName',galleryName);
  formData.append('galleryImage',galleryImage);
  formData.append('status',status);

const AddGallery= async(e)=>{
    e.preventDefault();

    axios.post('https://tanish.xpertart.com/api/gallery/add-gallery',formData,{
        header: {
            'content-type': 'multipart/form-data'
          }
    }).then((response)=>{
        console.log(response);
        alert('gallery added successfully')
        
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      navigate('/gallery-list')
        
    }).catch((error)=>{
console.log(error);

    })

}
  return (
    <div>
      <h1 className='galheading'>Gallery-Form</h1>
       <form id='cateForm' onSubmit={AddGallery}>
      <div>
        <label for="category name">Gallery name: </label>
        <input type="text" name="galleryName" required  onChange={(e)=>SetGalleryName(e.target.value)} />
        <br />
        <label for="image">Gallery Image </label>
        <input type="file" name="Image" accept='image/' onChange={(e)=>SetGalleryImage(e.target.files[0])} />
        <br />
        <div onChange={(e)=>SetStatus(e.target.value)} value={status}>
    <label htmlFor="status">status</label>
    <br />
    <input type="radio" checked={status&&status === "Yes"} name='status' value='Yes' />YES
    <input type="radio" checked={status&&status==="No"} name='status' value='No' />No
</div>



        <button type="submit">Submit </button>

      </div>
    </form>

    </div>
  )
}

export default AddGallery
