import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import { FaEdit, FaToggleOff, FaToggleOn } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { FaEye, FaEyeLowVision } from "react-icons/fa6";
import { Bounce, toast } from 'react-toastify';



const CategoryList = () => {

    const [data, setData] = useState([])


    const fetchCategoryData = async () => {

        try {
            axios.get('https://tanish.xpertart.com/api/category/find-category')
                .then((response) => {
                    // console.log('response',response);
                    setData(response.data.data)
                })
                .catch((error) => {
                    console.log('error', error);

                })
                // CategoryList()
        } catch (error) {
            console.log('error'.error.message);
        }

    }

    

    useEffect(() => {
        fetchCategoryData();

    }, [])

    const UpdateStatus = async (id) => {
        try {
            axios.get(`https://tanish.xpertart.com/api/category/update-status?_id=${id}`)
                .then(async (response) => {
                    // console.log(response);

const confirm=window.confirm('Do you really want to change category status ?') 

if(confirm){


                    toast.success(response.data.message, {
                        position: "top-center",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                    await fetchCategoryData()
                }
                }).catch((error) => {
                    console.log("error", error);
                })
        } catch (error) { console.log("error", error); }
    }


    const UpdateIsFeatured = async (id) => {
        try {
            axios.get(`https://tanish.xpertart.com/api/category/update-featured?_id=${id}`).then(async (response) => {
                // console.log(response);

                const confirm=window.confirm('Do you really want to change category featured ?')
                if(confirm){
                    
                
                toast.success(response.data.message, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                await fetchCategoryData()
                }
            })
        } catch (error) {
            console.log("error", error.message);

        }
    }

    const usetoken = localStorage.getItem('token');


    const dleteCategory = (id) => {

        const formData = new FormData();
        formData.append('_id', id)

        axios.post(`https://tanish.xpertart.com/api/category/delete-category`, formData, {
            headers: {
                'authorization': usetoken
            }

        }).then(async (response) => {
            console.log(response);
            let confirm = window.confirm("Do you really want to delete category ?");

            if (confirm) {
                
            
            await toast.success(response.data.message, {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            await fetchCategoryData()
        }

        }).catch((error) => {
            console.log(error);

        })
    }


    return (
        <div className='container'>
            <div className='container'>
                <h1>Category List</h1>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        {/* <th>Category Id</th> */}
                        <th>Image URL</th>
                        <th>Edit</th>
                        <th>Status</th>
                        <th>Feature</th>
                        <th >Delete</th>

                    </tr>
                </thead>
                <tbody>

                    {
                        data && data.map((element, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{element.category}</td>
                                {/* <td>{element._id}</td> */}
                                <td className='categoryImage'><img src={element.image} alt={element.category} /></td>
                                <td>
                                    <Link to={`/update-category-form/${element._id}`}><FaEdit title='EDIT✍️' style={{ color: 'black' }} /></Link></td>
                                <td>

                                    {element.status && element.status === "Y" ? (<FaToggleOn onClick={() => UpdateStatus(element._id)} />) : (<FaToggleOff onClick={() => UpdateStatus(element._id)} />)}
                                </td>


                                <td>
                                    {element.isFeature && element.isFeature === "Y" ? (<FaEye onClick={() => UpdateIsFeatured(element._id)} />) : (<FaEyeLowVision onClick={() => UpdateIsFeatured(element._id)} />)}
                                </td>

                                <td ><button style={{ backgroundColor: 'red', width: '47%' }} onClick={() => dleteCategory(element._id)} >delete-category</button></td>

                            </tr>

                        ))
                    }

                </tbody>
            </Table>
        </div>
    )
}


export default CategoryList
