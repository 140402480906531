import React from 'react'

const NotFound404 = () => {
  return (
    <div>
      not Found
    </div>
  )
}

export default NotFound404
