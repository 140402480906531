import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import { MdEditSquare } from "react-icons/md";
import { Link } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import { TiStarFullOutline ,} from "react-icons/ti";
import { IoMdStarOutline } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { FaToggleOn,FaToggleOff } from "react-icons/fa";
const MyProductList = () => {

const [data,SetData]=useState('')
    const MyProListFetch=()=>{
        axios.get(`http://localhost:5000/api/my-products/find-products`).then((response)=>{
            console.log(response);
            SetData(response.data.product);
        }).catch((error)=>{
            console.log(error);
            
        })
    }

    useEffect(()=>{
        MyProListFetch()
    },[])

    const UpdateStatus = async (id) => {
      try {
        axios.get(`http://localhost:5000/api/my-products/change-status?_id=${id}`).then(async (response) => {
          // console.log(response);
          const confirm=window.confirm('Do you really want to change myproduct status ?')
          if(confirm){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          })
          await MyProListFetch()
        }
  
          // alert('Changed product status=>>>>');
          //  await fetchmyProductData()
  
        }).catch((error) => {
          toast.success(error.message, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          })
  
        })
      } catch (error) {
        toast.success(error.message, {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
  
      }
    }
  
  
  
    const UpdateSpecial = async (id) => {
      try {
        axios.get(`http://localhost:5000/api/my-products/update-is-special?_id=${id}`).then(async (response) => {
          // console.log(response);
          const confirm=window.confirm('Do you really want to change isSpecial status ?')
          if(confirm){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          })
          await MyProListFetch()
        }
  
          // alert('Changed product status=>>>>');
          //  await fetchmyProductData()
  
        }).catch((error) => {
          toast.success(error.message, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          })
  
        })
      } catch (error) {
        toast.success(error.message, {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
  
      }
    }
  

    const deleteUser = async (id) => {
      try {
        axios.get(`http://localhost:5000/api/my-products/delete-products?_id=${id}`).then((response) => {
          // console.log(response);
          // alert('delete product')

          const confirm=window.confirm('Do you really want to delete myProduct ?')
          if(confirm){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          MyProListFetch()
        }
        }).catch((error) => {
          console.log(error);
  
        })
      } catch (error) {
        console.log(error);
  
      }
    }
  
  
  


  return (
    <div>
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>my_product_name</th>
          <th>productImage</th>
          <th>ProductPrice</th>
          <th>categoryId</th>
          <th>SubCategory</th>
          <th>Description</th>
          <th>Added-by</th>
          <th>IsSpecial</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data&&data.map((element,index)=>(

    
        <tr>
          <td>{index+1}</td>
          <td>{element.my_product_name}</td>
          <td><img src={element.image} alt={element.image} style={{height:'30px', width:'30px'}} /></td>
          <td>{element.ProductPrice}</td>
          <td>{element.categoryId&&element.categoryId.category}</td>
          <td>{element.subcategoryId&&element.subcategoryId.name}</td>
          <td>{element.description} </td>
          <td>{element.addedBy.name}</td>
<td>


     {
                  element.isSpecial && element.isSpecial === 'Y' ? (<TiStarFullOutline title='isSpecial'  onClick={() => UpdateSpecial(element._id)} />
                  ) : (<IoMdStarOutline title='Not isSpecial' onClick={() => UpdateSpecial(element._id)} />)
                }
  
  </td>
          <td>
<MdDelete title='DELETE' onClick={() => deleteUser(element._id)} />

          <Link to={`/update/my-product/${element._id}`}> <MdEditSquare style={{color:'black'}}/></Link>
           {
            element.status&&element.status==='Y'?(<FaToggleOn title='On' onClick={()=>UpdateStatus(element._id)} />):(<FaToggleOff title='Off' onClick={()=>UpdateStatus(element._id)} />)
           }
            </td>
        </tr>
    ))};
      </tbody>
    </Table>
    </div>
  )
}

export default MyProductList
