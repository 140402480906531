import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import { BsToggle2On, BsToggle2Off } from "react-icons/bs";
import { Bounce, toast } from 'react-toastify';
import { FaTrash } from "react-icons/fa";



const SliderList = () => {
    const [list, SetList] = useState('')

    const viewSliderList = () => {
        axios.get('https://tanish.xpertart.com/api/slider/find-slider').then((response) => {
            // console.log(response);
            SetList(response.data.data)
        }).catch((error) => {
            console.log(error);

        })
    }
    useEffect(() => {
        viewSliderList()
    }, [])
    // viewSliderList()

    const updateStatus = async (id) => {
        axios.get(`https://tanish.xpertart.com/api/slider/update-status?_id=${id}`)
            .then(async (response) => {
                // console.log(response);
                const confirm=window.confirm('Do you really want to change slider status ?')
                if(confirm){
                await toast.success(response.data.message, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });

                viewSliderList()
            }
            }).catch((error) => {
                console.log(error);

            })
    }


    const deleteSlider = async (id) => {
        axios.get(`https://tanish.xpertart.com/api/slider/delete-slider?_id=${id}`).then(async (response) => {
            console.log(response);
    
            const confirmation=window.confirm('Do you really want to delete slider ?')
if(confirmation){
            await toast.success(response.data.message, {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            viewSliderList()
        }
            return;
        }).catch((error) => {
            console.log(error);

        })
    }

    return (
        <div>
            <Table striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Slider Name</th>
                        <th style={{ width: '1px' }}>Image</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {list && list.map((element, index) => (


                        <tr>
                            <td>{index + 1}</td>
                            <td>{element.name} </td>
                            <td><img src={element.image} alt={"img"} style={{ width: '520px' }} /> </td>
                            <td>
                                <FaTrash title='DELETE' onClick={() => deleteSlider(element._id)} />

                                {element.status && element.status === 'Y' ? (<BsToggle2On title='status on' onClick={() => updateStatus(element._id)} />) : (<BsToggle2Off title='status Off' onClick={() => updateStatus(element._id)} />)}
                            </td>
                        </tr>
                    ))}

                </tbody>
            </Table>
        </div>
    )
}

export default SliderList
