import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import './CategoryProduct.css';

const CategoryProduct = () => {
    const { catpId } = useParams();
    const [data, setData] = useState([]);

    const categoryByProduct = async (id) => {
        try {
            const response = await axios.get(`http://localhost:5000/api/product-list-by-categoryId?categoryId=${id}`);
            console.log('response>>>>>>', response);
            setData(response.data.data); // Update to access the correct part of the response
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        categoryByProduct(catpId);
    }, [catpId]);

    return (
        <section id="categoryPage">
            <div className="container">
                <div className="category_heading">
                    <h1>Feature your own favoured and Choose your be-loved <i className="fa-regular fa-face-kiss-wink-heart"></i></h1>
                </div>
                <div className="categoryFlex">
                    {data ? data.map((element) => (
                        <div className="category" key={element._id}>
                            <Link to={`/product-detail/${element._id}`}>
                                <div className="categoryImage">
                                    <img src={element.image} alt="proImage" />
                                </div>
                            </Link>
                            <div className="categoryData">
                                <h1>{element.product_name}</h1>
                                <h2>${element.price}</h2>
                                <Link to={`/product-detail/${element._id}`}><i className="fa-solid fa-cart-shopping"></i> Buy Now</Link>
                            </div>
                        </div>
                    )) : (
                        <p>No products found in this category.</p>
                    )}
                </div>
            </div>
        </section>
    );
}

export default CategoryProduct;
