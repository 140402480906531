import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Feature from './components/main/Feature';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Signin from './components/main/Signin';
import Signup from './components/main/Signup';
import NotFound404 from './components/main/NotFound404';
import About from './components/main/About';
import Support from './components/main/Support';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Product from './components/products/Product';
import ContactUs from './components/main/ContactUs';
import NewContactList from './components/main/NewContactList';
import ProductForm from './components/main/ProductForm';
import ProductList from './components/main/Productlist';
import CategoryList from './components/main/CategoryList';
import CategoryForm from './components/main/CategoryForm';
import ProductDetails from './components/products/ProductDetail';
import EditProductForm from './components/EditProductForm';
import UpdateCategoryForm from './components/main/UpdateCateForm';
import Cart from './components/main/Cart';
import AddSliderForm from './components/Sliders/AddSliderForm';
import SliderList from './components/Sliders/SliderList';
import SubCategoryForm from './components/main/SubCategoryForm';
import AddGallery from './components/main/AddGallery';
import GalleryList from './components/main/GalleryList';
import AddBlogForm from './components/main/AddBlogForm';
import BlogList from './components/main/BlogList';
import SubCategoryList from './components/main/SubCategoryList';
import MyProduct from './components/MyProducts/MyProduct';
import MyProductList from './components/MyProducts/MyProductList';
import EditMyProduct from './components/MyProducts/EditMyProduct';
import Loading from './components/Loading/Loading';
import { useEffect, useState } from 'react';
import axios from 'axios';
import CategoryProduct from './components/CategoryProduct/CategoryProduct';

function App() {

  const [loading, SetLoading] = useState(false)
  useEffect(() => {
    axios.interceptors.request.use((config) => {
      SetLoading(true)

      return config;

    }, (error) => {
      return Promise.reject(error)
    }
    )

    axios.interceptors.response.use((config) => {
      SetLoading(false)

      return config;

    }, (error) => {
      return Promise.reject(error)
    }
    )

  }, [])

  return (
    <BrowserRouter>
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Loading show={loading} />
      <Routes>
        <Route path='/' element={<Feature />} />
        <Route path='/sign-in' element={<Signin />} />
        <Route path='/sign-up' element={<Signup />} />
        <Route path='/about' element={<About />} />
        <Route path='/products' element={<Product />} />
        <Route path='/product-detail/:id' element={<ProductDetails />} />
        <Route path='/contact-us' element={<ContactUs />} />
        {/* <Route path='/add-product' element={<AddProduct />} /> */}
        <Route path='/new-contact-list' element={<NewContactList />} />
        <Route path='/product-form' element={<ProductForm />} />
        <Route path='/edit-product-form/:proId' element={<EditProductForm />} />
        <Route path='/category-form' element={<CategoryForm />} />
        <Route path='/product-list' element={<ProductList />} />
        <Route path='/category-list' element={<CategoryList />} />
        <Route path='/update-category-form/:catId' element={<UpdateCategoryForm />} />
        <Route path='/support' element={<Support />} />
        <Route path='/add-cart' element={<Cart />} />
        <Route path='/add-slider' element={<AddSliderForm />} />
        <Route path='/slider-list' element={<SliderList />} />
        <Route path='/subcategory-form' element={<SubCategoryForm />} />
        <Route path='/gallery-form' element={<AddGallery />} />
        <Route path='/gallery-list' element={<GalleryList />} />
        <Route path='/add-blog' element={<AddBlogForm />} />
        <Route path='/blog-list' element={<BlogList />} />
        <Route path='/sub-cat-list' element={<SubCategoryList />} />
        <Route path='/my-products' element={<MyProduct />} />
        <Route path='/my-products-list' element={<MyProductList />} />
        <Route path='/update/my-product/:myId' element={<EditMyProduct />} />
        <Route path='/category-product/:catpId' element={<CategoryProduct/>}/>


        <Route path='*' element={<NotFound404 />} />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
