import axios from 'axios';
import React, { useState } from 'react'
import "./CategoryForm.css";
import { useNavigate } from 'react-router-dom';
import { toast ,Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CategoryForm = () => {

  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [status,SetStatus]=useState('')

  const HandleChooseFile = (e) => {
    let value = e.target.files[0]
    setImage(value);
  }
  const formData = new FormData();
  formData.append('category', category);
  formData.append('image', image)



  const navigate = useNavigate();
  const handleSumbit = async (e) => {
    e.preventDefault();

    // http://localhost:5000/api/category/add-category"


    axios.post('https://tanish.xpertart.com/api/category/add-category', formData, {
      header: {
        'content-type': 'multipart/form-data'
      }
    }).then(function (response) {
      console.log(response);

      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });


    }).catch((error) => {
      console.log(error, 'error');

    })

    navigate('/category-list')

  };
  return (
    <form id='cateForm' onSubmit={handleSumbit}>
      <div>
        <label for="category name">Category name: </label>
        <input type="text" name="categoryname" required onChange={(e) => setCategory(e.target.value)} />
        <br />
        <label for="image">Image </label>
        <input type="file" name="lastname" accept='image/' required onChange={HandleChooseFile} />
        <br />
        <div onChange={(e)=>SetStatus(e.target.value)} value={status}>
    <label htmlFor="status">status</label>
    <br />
    <input type="radio" name='status' />YES
    <input type="radio" name='status' />No
</div>



        <button type="submit">Submit </button>

      </div>
    </form>


  )
}

export default CategoryForm