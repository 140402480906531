
import './AddBlogForm.css'
import axios from 'axios';
import React, { useState } from 'react'
import { Bounce, toast } from 'react-toastify';


const AddBlogForm = () => {
  const [blogName, SetBlogName] = useState('');
  const [blogUrl, SetBlogUrl] = useState('');
  const [status, SetStatus] = useState('')


  const AddBlog = async (e) => {
    e.preventDefault();

    if (!blogName) {
      toast.error("Please Enter blogName", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return
    }

    if (!blogUrl) {
      toast.error("Please Enter blogUrl", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return
    }

    if (!status) {
      toast.error("Please choose status", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return
    }

    const blogData = {
      blogName: blogName,
      blogUrl: blogUrl,
    }

    axios.post('https://tanish.xpertart.com/api/blog/add-blog', blogData)
      .then(async (response) => {

        alert('blog added successfully')
        console.log(response, "response");

        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });

      }).catch((error) => {
        // console.log(error.message);
        toast.error(error.message, {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });


      })

  }
  return (
    <div>
      <form id='cateForm' onSubmit={AddBlog}>
        <div>
          <label for="category name">Blog name: </label>
          <input type="text" onChange={(e) => SetBlogName(e.target.value)} value={blogName} />
          <br />
          <label for="image">Blog URL </label>
          <input type="url" onChange={(e) => SetBlogUrl(e.target.value)} value={blogUrl} />
          <br />
          <div onChange={(e) => SetStatus(e.target.value)} value={status}>
            <label htmlFor="status">status</label>
            <br />
            <input type="radio" name='status' />YES
            <input type="radio" name='status' />No
          </div>



          <button type="submit">Submit </button>

        </div>
      </form>

    </div>
  )
}

export default AddBlogForm
